import React, {useContext, useEffect, useState} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Badge,
    Paper,
    useMediaQuery,
    useTheme,
    Stack,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    Backdrop,
    CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useWebSocketConnection from "./useWebSocketConnection";
import {AppContext} from "../AppContext";
import {useParams} from "react-router-dom";
import {DotSelect} from "./DotSelect";

export interface Project {
    _id: string;
    name: string;
    status?: 'Voting' | 'Completed' | 'NotStarted'
    userStories: Story[];
}

export interface Story {
    _id: string;
    name: string;
    totalPoints: number;
    userPoints?: number;
    description: string;
    completed: boolean;
}

export const UserStories  = ({monitor = false} : {monitor?: boolean}) => {
    const params = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {lastMessage, sendMessage, projectId} = useContext(AppContext);
    const project  = useWebSocketConnection(lastMessage, sendMessage, params.projectId, params?.userId??localStorage.getItem(params.projectId!)??'');
    const [totalAvailablePoints, setTotalAvailablePoints] = useState(10); // Totalt antal tillgängliga poäng
    const [usedPoints, setUsedPoints] = useState<number>(0); // Antal använda poäng
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isRegistered, setIsRegistered] = React.useState(false);
    const [name, setName] = React.useState('');
    const [waitForResponse, setWaitForResponse] = React.useState(false);
    const [isVoting, setIsVoting] = React.useState(false);
    const [userId, setUserId] = React.useState(params?.userId);
    const [initialOrder, setInitialOrder] = useState<string[]>([]); // Spara ID:n för den ursprungliga ordningen
    const [storiesMap, setStoriesMap] = useState<Map<string, Story>>(new Map());
    const handlePointsChange = (selectedPoints: number, userStoryId: string) => {
       if (sendMessage === undefined) return;
        sendMessage(JSON.stringify({
            action: 'addPoint',
            userStoryId,
            projectId: params.projectId,
            userId: userId,
            points: selectedPoints
        }));
    };
    useEffect(() => {
        if (params.projectId) {
            setTotalAvailablePoints(project?.userStories.length??10);
            setWaitForResponse(false);
            if (localStorage.getItem(params.projectId) === null && params?.userId) localStorage.setItem(params.projectId!, params?.userId);
            if (localStorage.getItem(params.projectId) !== null && project?.name) {
                //Clean up local storage if user is not registered to this project
                if (project?.user?._id !== localStorage.getItem(params.projectId!) ) {
                    localStorage.removeItem(params.projectId!);
                } else {
                    setIsRegistered(true);
                    setUsedPoints(project?.userStories?.reduce((acc, story) => acc + (story.userPoints ? story.userPoints : 0), 0) || 0);
                    setIsVoting(project?.status === 'Voting' && project?.user?.status !== 'Completed');
                    setUserId(localStorage.getItem(params.projectId!)!);
                }
            }
        }
    }, [project]);

    const handleRegister = () => {
        if (sendMessage === undefined) return;
        sendMessage(JSON.stringify({
            action: 'registerUser',
            projectId: params.projectId,
            name
        }));
        setWaitForResponse(true);
    }

    const handleClose = () => {
        setIsRegistered(false);
    };
    const handleStopVoting = () => {
        if (sendMessage === undefined) return;
        sendMessage(JSON.stringify({
            action: 'stopVoting',
            projectId: params.projectId,
            userId
        }));
    }

    useEffect(() => {
        if (project?.userStories) {
            if (initialOrder.length === 0 || monitor) {
                // Spara den initiala ordningen av ID:n när listan först laddas
                setInitialOrder(project.userStories.map(story => story._id));

                // Initiera Map med initiala stories
                const initialMap = new Map(project.userStories.map(story => [story._id, story]));
                setStoriesMap(initialMap);
            } else {
                // Uppdatera Map med nya stories
                const updatedMap = new Map(storiesMap);
                project.userStories.forEach(story => updatedMap.set(story._id, story));
                setStoriesMap(updatedMap);
            }
        }
    }, [project]);

// Rendera listan baserat på initialOrder och hämta data från storiesMap
    function renderStories() {
        return initialOrder.map(id => {
            const story = storiesMap.get(id);
            if (story) {
                return <DisplayUserStories key={story._id} {...story} />;
            }
            return null; // eller hantering för när en story inte finns
        });
    };
    const DisplayUserStories = (story: Story) => <Accordion
        key={story._id}
        expanded={expanded}
        onClick={()=>{}}
        sx={{ minWidth: isMobile ? 300 : 600, marginBottom: '10px' }}
    >
        <AccordionSummary
            expandIcon={ null }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ width: '100%' }}
        >
            <Grid container>
                <Grid item sm={1}>
                    <Box style={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        fontSize: '15px', // Större textstorlek för badge
                        width: '25px',
                        textAlign: 'center',
                        height: 'auto', // Justera höjden
                        borderRadius: '20px', // Runda hörn (om önskat)
                        padding: '0px',
                    }}>
                        {story.totalPoints}
                    </Box>
                </Grid>
                <Grid item sm={11}>
                    <Typography component={'div'} variant="h6" sx={{ flexGrow: 1, whiteSpace: 'normal' }}>
                        {story.name}
                    </Typography>
                </Grid>
                {isVoting &&
                    <Grid item sm={12} width={'100%'}>
                        <Box textAlign={'right'} width={'100%'}>
                            <DotSelect
                                numPoints={project?.userStories.length??10}
                                maxPoints={totalAvailablePoints }
                                onPointsChange={handlePointsChange}
                                userStoryId={story._id}
                                userPoints={story.userPoints}
                                usedPoints={usedPoints}
                            />
                        </Box>

                    </Grid>
                }

            </Grid>

        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="body1">{story.description}</Typography>
        </AccordionDetails>
    </Accordion>;

    return (
        <Box sx={{ backgroundColor: '#f4f4f4', p: 3, height: '100%' }}>
            {
                (isRegistered || monitor) &&
                <Box display="flex" justifyContent="center" mb={4}>
                    <Paper elevation={3} style={{ maxWidth: 800, width: '100%', padding: '20px' }}>
                        <Typography variant="h4" textAlign="center" gutterBottom>
                            {project?.name}
                        </Typography>
                        <Box>
                            {
                                isVoting &&
                                <span>Points remaining: <b>{totalAvailablePoints - usedPoints}</b></span>
                            }
                            {isVoting && (totalAvailablePoints - usedPoints) === 0 && <Button sx={{marginLeft: 3}} variant="contained" onClick={handleStopVoting}>I am done!</Button>}
                            {project?.user?.status === 'Completed' && <Badge color="success" badgeContent="Completed" sx={{ marginLeft: 5 }} />}
                        </Box>
                        <Box style={{ width: '100%' }}>
                            {renderStories()}
                        </Box>
                    </Paper>
                </Box>
            }

            <Dialog open={((!isRegistered && !monitor) && (!!project??false))} onClose={handleClose}>
                <DialogTitle>Register to {project?.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To start voting for the user stories you like the most, just enter your name here and click 'REGISTER'!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="name"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant={'contained'} disabled={name?.length === 0}  onClick={handleRegister}>Register</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waitForResponse}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

