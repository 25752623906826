import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

interface PointsSelectProps {
    maxPoints: number; // Den totala tillgängliga poängen
    onPointsChange: (selectedPoints: number, userStoryId: string) => void; // Callback för att hantera poängändringar
    userStoryId: string; // Id för den user story som poängen ska tilldelas
    userPoints?: number; // Antal poäng som användaren har tilldelats
    usedPoints: number; // Antal poäng som användaren har tilldelats
    numPoints: number; // Antal poäng totalt
}

export const DotSelect: React.FC<PointsSelectProps> = ({ maxPoints, onPointsChange, userStoryId, userPoints, usedPoints, numPoints = 10 }) => {
    const [selectedPoints, setSelectedPoints] = useState<number>(userPoints || 0);

    const handleChange = (event: any) => {
        const newValue = event.target.value as number;
        setSelectedPoints(newValue);
        onPointsChange(newValue, userStoryId);
    };
    // Skapar menyalternativ baserat på kvarvarande poäng
    const menuItems = [];
    for (let i = 1; i <= numPoints; i++) {
        const disabled = ((usedPoints - (userPoints??0)) + i) > maxPoints;
        menuItems.push(
            <MenuItem  key={i} value={i} disabled={disabled}>
                {i}
            </MenuItem>
        );
    }

    return (
        <FormControl sx={{width: '130px'}}>
            <Select
                labelId="points-select-label"
                id="points-select"
                size="small"
                type="number"
                variant="standard"
                value={selectedPoints}
                label="Your points"
                onChange={handleChange}
            >
                <MenuItem value={0}>Your points</MenuItem>
                {menuItems}
            </Select>
        </FormControl>
    );
};

