import {createContext} from 'react'
import {SendMessage} from "react-use-websocket";

interface AppContextType {
    payload?: any;
    lastMessage?: MessageEvent<any> | null;
    sendMessage?: SendMessage;
    getWebSocket?: any;
    projectId?: string;
}
export const AppContext = createContext<AppContextType>({});
