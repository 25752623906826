import { useEffect, useState } from 'react';
import { SendMessage } from 'react-use-websocket';

interface User {
    _id: string;
    name: string;
    status: 'Voting' | 'Completed' | 'NotStarted';
}
interface Story {
    _id: string;
    name: string;
    totalPoints: number;
    userPoints?: number;
    description: string;
    completed: boolean;
}
export interface Project {
    _id: string;
    name: string;
    user: User;
    status?: 'Voting' | 'Completed' | 'NotStarted'
    userStories: Story[];
}
const useWebSocketConnection = (
    lastMessage: MessageEvent<any> | null | undefined,
    sendMessage: SendMessage | undefined,
    projectId?: string,
    userId?: string
): Project => {
    const [payload, setPayload] = useState<any>(null);

    useEffect(() => {
        if (lastMessage !== null && lastMessage !== undefined) {
            try {
                const payload = JSON.parse(lastMessage.data);
                if (payload.routeKey === 'pong') {
                    console.log('pong');
                    return;
                }
                console.log(payload);
                if (payload.routeKey === 'getStories' || payload.routeKey === 'addPoint') setPayload(payload.data);

                if (payload.routeKey === 'updatedStories') {
                    if (sendMessage === undefined) return;
                    sendMessage(JSON.stringify({
                        action: 'getStories',
                        projectId: projectId,
                        userId: userId
                    }));
                }

                if (payload.routeKey === 'registerUser') {
                    localStorage.setItem(projectId!, payload.data.userId);
                    setPayload(payload.data.data);
                }
        } catch (e: any) {
            console.error('Not so serious error', e.toString(), lastMessage);
            return;
        }
        }
    }, [lastMessage]);

    useEffect(() => {
        if (sendMessage === undefined) return;
        sendMessage(JSON.stringify({
            action: 'getStories',
            projectId: projectId,
            userId: userId
        }));
    }, []);

    return payload;
};


export default useWebSocketConnection;
