import React from 'react';

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {UserStories} from "./components/UserStories";
import {DotVote} from "./components/DotVote";
import {AdminPage} from "./components/AdminPage";

export const App = () => {

    return (
        <React.Fragment>
            <Router>
                <Routes>
                    <Route path="/:projectId" element={<UserStories/>}/>
                    <Route path="/monitor/:projectId" element={<UserStories monitor={true}/>}/>
                    <Route path="/:projectId/:userId" element={<UserStories />}/>
                    <Route path="admin/:projectId/:userId" element={<AdminPage/>}/>
                </Routes>
            </Router>
        </React.Fragment>



    );
}