import { useEffect, useState } from 'react';
import { SendMessage } from 'react-use-websocket';

interface User {
    _id: string;
    name: string;
    status: 'Voting' | 'Completed' | 'NotStarted';
}

interface Story {
    _id: string;
    name: string;
    totalPoints: number;
    userPoints?: number;
    description: string;
    completed: boolean;
}
interface AdminPage {
    users?: User[];
    project?: Project;
    error?: string;
}

interface Project {
    _id: string;
    name: string;
    status?: 'Voting' | 'Completed' | 'NotStarted'
    userStories: Story[];
}
const useAdminWebSocketConnection = (
    lastMessage: MessageEvent<any> | null | undefined,
    sendMessage: SendMessage | undefined,
    projectId?: string,
    userId?: string
): AdminPage => {
    const [payload, setPayload] = useState<any>(null);

    useEffect(() => {
        if (lastMessage !== null && lastMessage !== undefined) {
            try {
                const payload = JSON.parse(lastMessage.data);
                if (payload.routeKey === 'pong') {
                    console.log('pong');
                    return;
                }
                console.log('payload', payload);
                if ( ['registerUser', 'getStories', 'addPoint','updatedStories', 'refreshAdminPage'].includes(payload?.routeKey)) {
                    if (sendMessage === undefined) return;
                    sendMessage(JSON.stringify({
                        action: 'getAdminPage',
                        projectId,
                        userId
                    }));
                }
                if (payload.routeKey === 'getAdminPage' || payload.routeKey === 'startStopVoting' || payload.routeKey === 'stopVoting') setPayload(payload.data);
            } catch (e: any) {
                console.error('Not so serious error', e.toString(), lastMessage);
                return;
            }
        }
    }, [lastMessage]);
    return payload;
};

export default useAdminWebSocketConnection;
