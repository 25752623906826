import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import useWebSocket from "react-use-websocket";
import { AppContext } from './AppContext';
import {ThemeProvider} from "@mui/material";
import {smallTheme, theme} from "./components/SmallTheme";
const socketUrl =`${process.env.REACT_APP_WEBSOCKET_API}`;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const Root = () => {
    const [shouldConnect, setShouldConnect] = useState(true);

    const {lastMessage, sendMessage, getWebSocket} = useWebSocket(socketUrl,
        {
            onOpen: () => console.log('DEBUG: web socket opened'),
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => {
                console.log('WebSocket closed with code', closeEvent.code);
                return shouldConnect;
            },
            reconnectInterval: 5000,
            reconnectAttempts: 10,
        });
    useEffect(() => {
        const heartBeatInterval = setInterval(() => {
            // Skicka ett hjärtslagsmeddelande
            sendMessage(JSON.stringify({
                action: 'ping'
            }));
            console.log('ping');
        }, 60000); // Skicka varje 30 sekunder

        return () => {
            clearInterval(heartBeatInterval);
        };
    }, [sendMessage]);
    return (
    <React.StrictMode>
        <AppContext.Provider value={{lastMessage, sendMessage, getWebSocket}}>
            <ThemeProvider theme={window.innerWidth < 600 ? smallTheme : theme}>
        <App />
            </ThemeProvider>
        </AppContext.Provider>
    </React.StrictMode>
    );
}
root.render(<Root />);

