import {
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemButton, ListItemIcon,
    ListItemText,
    Paper, TextField,
    Typography
} from "@mui/material";
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import useWebSocketConnection from "./useWebSocketConnection";
import React, {useContext, useEffect} from "react";
import {AppContext} from "../AppContext";
import {useParams} from "react-router-dom";
import useAdminWebSocketConnection from "./useAdminWebSocketConnection";
import InboxIcon from '@mui/icons-material/MoveToInbox';
export const AdminPage = () => {
    const params = useParams();
    const {lastMessage, sendMessage, projectId} = useContext(AppContext);
    const payload  = useAdminWebSocketConnection(lastMessage, sendMessage, params.projectId, params?.userId??localStorage.getItem(params.projectId!)??'');
    const buttonLabel = payload?.project?.status === 'Voting' ? 'Stop voting' : 'Start voting';
    const [isAdmin, setIsAdmin] = React.useState(false);
    useEffect(() => {
        if (sendMessage === undefined) return;
        sendMessage(JSON.stringify({
            action: 'getAdminPage',
            projectId: params.projectId,
            userId: params.userId
        }));
    }, []);
    useEffect(() => {
        if (payload?.project) {
            setIsAdmin(true);
        }
    }, [payload]);

    const handleStartStopVoting = () => {
        if (sendMessage === undefined) return;
        sendMessage(JSON.stringify({
            action: 'startStopVoting',
            status: payload?.project?.status === 'Voting' ? 'Completed' : 'Voting',
            projectId: params.projectId,
            userId: params.userId
        }));
    }

    return (
        <Box sx={{ backgroundColor: '#f4f4f4', p: 3, height: '100%' }}>
            {
                isAdmin &&
                <Box display="flex" justifyContent="center" mb={4} >
                    <Paper elevation={3} style={{ maxWidth: 800, width: '100%', padding: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} textAlign={'center'}>
                                <Typography component={'div'} variant="h4">Admin page</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component={'div'} variant="h6">Project: {payload?.project?.name}</Typography>
                            </Grid>
                        </Grid>

                        <Button variant="contained" onClick={handleStartStopVoting}>{buttonLabel }</Button>

                        <nav aria-label="secondary mailbox folders">
                            <List sx={{width: '300px'}}>
                                {
                                    payload?.users?.map((user: any) => (
                                        <ListItem disablePadding key={user._id}>
                                            <ListItemButton>
                                                <ListItemIcon title={user.status}>
                                                    {user.status === 'Voting' && <HowToVoteIcon />}
                                                    {user.status === 'Completed' && <CheckCircleIcon sx={{ color: 'green' }} />}
                                                    {user.status === 'NotStarted' && <NotStartedIcon />}
                                                </ListItemIcon>
                                                <ListItemText primary={user.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </nav>
                    </Paper>
                </Box>
            }

            <Dialog open={!isAdmin} >
                <DialogTitle>Unauthorized</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You tried to access an admin page without being authorized. Please contact the administrator IF you think this is an error.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>

    )
}