import {createTheme} from "@mui/material/styles";

const theme = createTheme();
const fontSize = '13px';
const smallTheme = createTheme(
    {
        typography: {
            fontSize: 16,
            h4: {
                fontSize: '2.5rem',
                '@media (max-width:600px)': {
                    fontSize: '1.5rem',
                },
            },
            // Du kan fortsätta lägga till fler stilar här
        },
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontSize: fontSize, // Ange önskad fontstorlek här
                        padding: '5px 1px'
                    },
                    adornedEnd: {
                        fontSize: fontSize
                    },
                    adornedStart: {
                        fontSize: fontSize
                    },
                    inputAdornedEnd: {
                        fontSize: fontSize
                    },
                    inputAdornedStart: {
                        fontSize: fontSize
                    },
                    inputHiddenLabel: {
                        fontSize: fontSize
                    },
                    inputMultiline: {
                        fontSize: fontSize
                    },
                    inputTypeSearch: {
                        fontSize: fontSize
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontSize: fontSize
                    }
                }
            },
            MuiInputLabel: {
                defaultProps: {
                    shrink: true,
                },
                styleOverrides: {
                    root: {
                        fontSize: fontSize,
                        //   marginTop: '-13px'
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    fontSizeMedium: '20px',
                    root: {
                        fontSize: '20px!important'
                    }
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    option: {
                        fontSize: fontSize
                    },
                    noOptions: {
                        fontSize: fontSize,
                    },
                    tag: {
                        fontSize: '8px',
                        height: 'unset'
                    },
                    root: {
                        //  marginTop: '1px!important', // Ange önskat paddingvärde här
                    },

                },
            },

            MuiInput: {
                styleOverrides: {
                    root: {
                        marginTop: '7px!important', // Ange önskat paddingvärde här
                    },
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontSize: '10px', // Ange önskad fontstorlek här,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontSize: fontSize, // Detta kommer att ange fontstorleken för <Typography>
                        // Lägg till fler stilar efter behov här...
                    },
                },
            },
            MuiStepConnector: {
                styleOverrides: {
                    root: {
                        marginLeft: '9px',
                    },
                    line: {
                        maxHeight: '10px',  /* Anpassa höjden efter dina behov */
                        minHeight: '10px',  /* Anpassa höjden efter dina behov */
                    },
                },
            },

        }
    }
);

export { theme, smallTheme, fontSize };